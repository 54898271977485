import axios from "@/api/http";
const BASE_URL = process.env.VUE_APP_GENERAL_URL;

/**
 * 总控-品类管理-二级品类-保存标签
 * @param {*} params
 * @returns
 */
export function postUpdateCategoryTag(params = {}) {
  return axios({
    method: "POST",
    url: `${BASE_URL}/update/category/tag`,
    data: params,
  });
}
