<template>
  <el-dialog
    :title="titleDialog"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="onHandleClose"
    width="600px"
    class="dialog"
    center
  >
    <div class="contents">
      <div class="switch-wrap">
        <span class="label">显示标签：</span>
        <el-switch
          class="switch"
          v-model="formData.is_show"
          :active-value="1"
          :inactive-value="0"
          :width="50"
        >
        </el-switch>
        <span class="suffix">{{ formData.is_show ? "开" : "关" }}</span>
      </div>

      <!-- 拖拽区域 start -->
      <div class="tag-list" v-if="formData.tag_list.length">
        <div
          class="tag"
          v-for="(child, index) of formData.tag_list"
          :key="index"
        >
          <div class="name">
            <el-input
              v-model="child.name"
              placeholder=""
              clearable
              maxlength="10"
            ></el-input>
          </div>
          <div class="icon-wrap" @click="onDelete(index)">
            <i class="icon el-icon-delete"></i>
          </div>
          <div class="icon-wrap">
            <i
              class="icon el-icon-plus"
              v-if="
                index === formData.tag_list.length - 1 &&
                formData.tag_list.length < MAX_TAG_LENGTH
              "
              @click="onCreate"
            ></i>
          </div>
          <div class="icon-wrap">
            <i class="icon el-icon-rank" @click="onRemove(index)"></i>
          </div>
        </div>
      </div>
      <!-- 拖拽区域 end -->

      <div v-else class="tag-add" @click="onCreate">
        <i class="el-icon-plus"></i>&nbsp;添加标签
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="onHandleClose">取消</el-button>
      <el-button type="primary" @click="onHandleSubmit" :loading="loadingSubmit"
        >保存</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import Sortable from "sortablejs";
import { postUpdateCategoryTag } from "@/api/general/category/index";

export default {
  name: "GoodsTagDialog",
  props: {},
  data() {
    return {
      dialogVisible: false,
      loadingSubmit: false,
      titleDialog: "编辑标签",
      MAX_TAG_LENGTH: 20, // tag最多个数
      formData: {
        cid: "", // 品类ID,
        is_show: 1, // 是否展示标签
        tag_list: [], // tag列表
      }, // 表单数据
      formDataClone: {}, // 复制一份，用于初始化
      sortableObj: null, // 拖拽对象
    };
  },
  methods: {
    /**
     * 初始化
     */
    async onInitData(data) {
      this.dialogVisible = true;
      this.formDataClone = cloneDeep(this.formData);
      if (data) {
        this.onResetEditData(data);
      }
    },
    /**
     * 回显编辑数据
     */
    onResetEditData(data) {
      Object.keys(data).forEach((_Key) => {
        this.formData[_Key] = data[_Key];
      });
      this.formData.is_show = data?.is_show || 0;
      if (!this.formData.tag_list.length) {
        this.onCreate();
      }
      this.$nextTick(() => {
        this.initSortable();
      });
    },
    /**
     * 初始化拖拽
     */
    initSortable() {
      const dom = this.$el.querySelectorAll(".tag-list");
      this.sortableObj = null;
      dom.forEach((item) => {
        this.sortableObj = Sortable.create(item, {
          // 触发拖拽的class
          handle: ".el-icon-rank",
          animation: 150,
          onEnd: ({ newIndex, oldIndex }) => {
            // 得到最新的列表
            const list = cloneDeep(this.formData.tag_list);
            const unit = list.splice(oldIndex, 1);
            list.splice(newIndex, 0, ...unit);
            this.formData.tag_list = [];
            this.$nextTick(() => {
              this.formData.tag_list = cloneDeep(list);
            });
            // console.log("🆒 initSortable data", newIndex, oldIndex, list);
          },
        });
      });
    },
    /**
     * 增加
     */
    onCreate() {
      const options = {
        id: 0,
        name: "",
      };
      this.formData.tag_list.push(options);
    },
    /**
     * 删除
     */
    onDelete(index) {
      this.formData.tag_list.splice(index, 1);
    },
    /**
     * 移动
     */
    onRemove(index) {},
    /**
     * 关闭操作
     */
    onHandleClose() {
      this.formData = {
        ...this.formDataClone,
      };
      this.dialogVisible = false;
    },
    /**
     * 确认
     */
    onHandleSubmit() {
      this.ajaxPostUpdateCategoryTag();
    },
    /**
     * 提交
     */
    async ajaxPostUpdateCategoryTag() {
      this.loadingSubmit = true;
      const params = {
        ...this.formData,
      };
      params.tag_list = params.tag_list.filter((child) => child.name);
      try {
        await postUpdateCategoryTag(params);
        this.$message.success("操作成功");
        this.$emit("on-change");
        this.onHandleClose();
      } catch (err) {
        console.log("ajax postUpdateCategoryTag err", err);
      } finally {
        this.loadingSubmit = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$color: #13ae67;

.dialog {
  .contents {
    padding-top: 30px;
    padding-bottom: 20px;
    .switch-wrap {
      display: flex;
      align-content: center;
      gap: 10px;
      .label {
        width: 100px;
        font-size: 14px;
        font-weight: 550;
      }
    }
    .tag-list {
      max-height: 400px;
      margin-top: 20px;
      overflow-y: auto;
      .tag {
        position: relative;
        display: flex;
        align-content: center;
        gap: 15px;
        padding-right: 15px;
        margin-bottom: 10px;
        .icon-wrap {
          width: 15px;
        }
        .name {
          flex: 1;
        }
        .icon {
          position: relative;
          top: 10px;
          cursor: pointer;
        }
      }
    }
    .tag-add {
      display: inline-block;
      margin-top: 50px;
      color: $color;
      cursor: pointer;
    }
  }
}
</style>
