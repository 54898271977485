var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "dialog",
      attrs: {
        title: _vm.titleDialog,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "600px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onHandleClose,
      },
    },
    [
      _c("div", { staticClass: "contents" }, [
        _c(
          "div",
          { staticClass: "switch-wrap" },
          [
            _c("span", { staticClass: "label" }, [_vm._v("显示标签：")]),
            _c("el-switch", {
              staticClass: "switch",
              attrs: { "active-value": 1, "inactive-value": 0, width: 50 },
              model: {
                value: _vm.formData.is_show,
                callback: function ($$v) {
                  _vm.$set(_vm.formData, "is_show", $$v)
                },
                expression: "formData.is_show",
              },
            }),
            _c("span", { staticClass: "suffix" }, [
              _vm._v(_vm._s(_vm.formData.is_show ? "开" : "关")),
            ]),
          ],
          1
        ),
        _vm.formData.tag_list.length
          ? _c(
              "div",
              { staticClass: "tag-list" },
              _vm._l(_vm.formData.tag_list, function (child, index) {
                return _c("div", { key: index, staticClass: "tag" }, [
                  _c(
                    "div",
                    { staticClass: "name" },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "",
                          clearable: "",
                          maxlength: "10",
                        },
                        model: {
                          value: child.name,
                          callback: function ($$v) {
                            _vm.$set(child, "name", $$v)
                          },
                          expression: "child.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "icon-wrap",
                      on: {
                        click: function ($event) {
                          return _vm.onDelete(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "icon el-icon-delete" })]
                  ),
                  _c("div", { staticClass: "icon-wrap" }, [
                    index === _vm.formData.tag_list.length - 1 &&
                    _vm.formData.tag_list.length < _vm.MAX_TAG_LENGTH
                      ? _c("i", {
                          staticClass: "icon el-icon-plus",
                          on: { click: _vm.onCreate },
                        })
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "icon-wrap" }, [
                    _c("i", {
                      staticClass: "icon el-icon-rank",
                      on: {
                        click: function ($event) {
                          return _vm.onRemove(index)
                        },
                      },
                    }),
                  ]),
                ])
              }),
              0
            )
          : _c("div", { staticClass: "tag-add", on: { click: _vm.onCreate } }, [
              _c("i", { staticClass: "el-icon-plus" }),
              _vm._v(" 添加标签 "),
            ]),
      ]),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onHandleClose } }, [
            _vm._v("取消"),
          ]),
          _c(
            "el-button",
            {
              attrs: { type: "primary", loading: _vm.loadingSubmit },
              on: { click: _vm.onHandleSubmit },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }